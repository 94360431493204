.main-section{
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    margin:0%;

}

@media screen and (min-width: 768px) {
    .main-section{
        height: 95vh;
    }
}

body {
    min-height: 100vh;
    font-family: 'Barlow Semi Condensed', sans-serif;
    color: lightgray;
    background-color: #FFFFFF;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
    background-repeat: repeat;
    background-size: cover;
    background-color: #4f4b4b;
}

.btn {
    min-width: 85%;
    min-height: 3rem;
    background-color: #7aa356;
    color: black;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 90%;
}

.plakat-img {
    margin: 5vh 5vh 5vh 0;
    border-radius: 10px;
    object-fit: scale-down;
    min-height: 500px;
    max-height: 90vh;
    max-width: 75%;
}

.plakat-img-phone{
    width: 90%;
    border-radius: 7px;
    margin: 5vh 0 0 0 !important;
}
.no-spacing{
    padding: 0%;
    margin: 0%;
}

.button-padding{
    margin: 5vh !important;
}

.footer-section{
    min-height: 5vh;
}


.flex_justify_center {
    justify-content: center;
}
