.main-section {
  width: 100%;
  margin: 0%;
  padding-left: 0%;
  padding-right: 0%;
}

@media screen and (width >= 768px) {
  .main-section {
    height: 95vh;
  }
}

body {
  color: #d3d3d3;
  background-color: #4f4b4b;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  background-repeat: repeat;
  background-size: cover;
  min-height: 100vh;
  font-family: Barlow Semi Condensed, sans-serif;
}

.btn {
  color: #000;
  background-color: #7aa356;
  min-width: 85%;
  min-height: 3rem;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 90%;
}

.plakat-img {
  object-fit: scale-down;
  border-radius: 10px;
  max-width: 75%;
  min-height: 500px;
  max-height: 90vh;
  margin: 5vh 5vh 5vh 0;
}

.plakat-img-phone {
  border-radius: 7px;
  width: 90%;
  margin: 5vh 0 0 !important;
}

.no-spacing {
  margin: 0%;
  padding: 0%;
}

.button-padding {
  margin: 5vh !important;
}

.footer-section {
  min-height: 5vh;
}

.flex_justify_center {
  justify-content: center;
}
/*# sourceMappingURL=index.a8ffde8f.css.map */
